<template>
  <div class="container-fluid">
    <div class="bg"></div>
    <div class="row L1">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 text-center">
            <h1><b>Meteor Master AI </b></h1>
            <p>基于深度学习技术，在视频录像、照片与直播画面中快速找出流星</p>
            <div class="btnbox hidden-xs">
              <a class="btn" v-if="isWin" href="https://www.microsoft.com/store/productid/9PKSMKZ7C10N?hl=zh-cn&gl=cn" target="_blank">前往微软商店</a>
              <a class="btn" v-else href="https://apps.apple.com/cn/app/meteor-master-ai-%E5%BF%AB%E9%80%9F%E6%89%BE%E5%87%BA%E6%B5%81%E6%98%9F/id6458742068?mt=12" target="_blank">前往AppStore</a>
              <div class="tip">
                <p><i class="gg-check-o"></i>一次付费永久使用</p>
                <p><i class="gg-check-o"></i>包含未来所有更新</p>
                <p><i class="gg-check-o"></i>无广告无订阅无内购</p>
                <p><i class="gg-check-o"></i>不限安装数量</p>
                <p><i class="gg-check-o"></i>支持多开</p>
              </div>
            </div>
            <div class="btnbox visible-xs">
              <a class="btn" href="javascript:void(0);">请前往桌面端下载</a>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="screenshot swiper-box">
              <swiper ref="mySwiper" :options="swiperOpt">
                <swiper-slide>
                  <img  draggable="false" class="img-responsive" src="@/assets/img/meteormaster/1.png" alt="" srcset="" >
                </swiper-slide>
                <swiper-slide>
                  <img  draggable="false" class="img-responsive" src="@/assets/img/meteormaster/7.png" alt="" srcset="" >
                </swiper-slide>
                <swiper-slide>
                  <img  draggable="false" class="img-responsive" src="@/assets/img/meteormaster/8.png" alt="" srcset="" >
                </swiper-slide>
                <swiper-slide>
                  <img  draggable="false" class="img-responsive" src="@/assets/img/meteormaster/6.png" alt="" srcset="" >
                </swiper-slide>
                <swiper-slide>
                  <img  draggable="false" class="img-responsive" src="@/assets/img/meteormaster/9.png" alt="" srcset="" >
                </swiper-slide>
                <div slot="pagination" class="swiper-pagination swiper-pagination-white"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row L2">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <div class="page-header">
              <h1>工具特点</h1>
            </div>
            <h3>支持视频录像、照片(RAW/JPG)、在线直播、OBS摄像头<span>无论是视频文件，还是拍的流星延时，还是直播信号，甚至是OBS的虚拟摄像头都能够支持，也可以多开</span></h3>
            <h3>AI引擎，又快又准<span>支持针对流星、人造飞行物、红色精灵的识别，AI会不断自我学习，提高精度。支持CUDA加速(win)、支持coreML(mac)，充分利用GPU性能</span></h3>
            <h3>解放双手，自动启停<span>支持根据特定地点经纬度，自动根据每天的日出/日落、天文晨光/黑夜开启和停止分析</span></h3>
            <h3>快速分析，充分利用电脑性能<span>充分利用多线程，专为长达几个小时的监控录像设计，相比于人工挑选，省时又省事</span></h3>
            <h3>快速回放、挑选、导出片段<span>一边后台分析一边检查并筛选流星，支持自定义星标，一键导出流星片段，甚至还能直接导出流星合集</span></h3>
            <h3>一键生成流星照片、延时摄影<span>不仅可以通过划过的流星轨迹生成一张“长曝光”的照片，也能为前一晚的视频录像生成延时摄影视频</span></h3>

            <div class="page-header">
              <h1>视频教程</h1>
            </div>
            <div class="bilibili">
              <a href="https://www.bilibili.com/video/BV1kS421K7pK/" target="_blank">
                <img src="@/assets/img/meteormaster/learning.jpg" alt="" srcset="">
              </a>
              
            </div>
            <div class="page-header">
              <h1>教程：分析一个视频录像</h1>
            </div>
            <p>1. 启动软件，点击“分析视频”按钮添加一个视频文件，您可以在上方看到该视频的画面。如没有显示画面，请将视频转码为1080或4K、mp4、h264、8bit的视频并进行重试。</p>
            <p>2. 点击“设置蒙版”按钮，并使用鼠标在画面上绘制不需要分析的区域，将其涂抹成红色。可以通过保存和加载蒙版图片文件来复用您的蒙版。您也可以使用自己喜欢的工具进行蒙版的制作，只需要在透明png文件中涂抹红色即可。</p>
            <p>3. 点击确认蒙版（形似对勾的按钮）后，再点击“开始分析”，软件将会持续在后台分析流星，并把结果逐步显示在下方列表中，屏幕上也会显示进度条。您可以在下方列表中看到每个流星片段持续多长时间、在哪个时间点出现，点击可以预览该片段的画面。</p>
            <p>4. 分析得到的流星结果不一定是准确的，有可能是误报，您可以点击片段右上角的x、或是选中片段后按下del来删除这个片段；您也可以点击星标或是按下f键来为选中的片段打星。</p>
            <p>5. 如果您在设置中启用了多线程分析，那么分析的过程是从多个时间段同时进行的，因此列表中的结果并不一定是按照时间顺序排列的。当分析结束时，您可以通过点击排序按钮查看排序后的结果。</p>
            <p>6. 每一个片段都可以点击片段预览图上方的“快速导出”按钮导出一个视频片段，这个片段将根据您的设置，保存在原文件的旁边或是指定的文件夹中。</p>
            <p>7. 当整个视频分析完毕后，可以点击“导出所有片段”按钮，您将在弹出的窗口中查看具体的导出选项，也支持将所有片段合并为一个合集视频进行导出。</p>

            <div class="page-header">
              <h1>教程：直播同时分析流星</h1>
            </div>
            <p>1. 启动软件，点击“分析直播流”或是“分析OBS摄像头”或是“向Meteor Master AI推流”，请确保在点击并打开前他们已经正常运行中并正在推送画面。</p>
            <p>2. 点击“设置蒙版”按钮，并使用鼠标在画面上绘制不需要分析的区域，将其涂抹成红色。可以通过保存和加载蒙版图片文件来复用您的蒙版。您也可以使用自己喜欢的工具进行蒙版的制作，只需要在透明png文件中涂抹红色即可。</p>
            <p>3. 点击确认蒙版（形似对勾的按钮）后，再点击“开始分析”，软件将会持续在后台分析流星，并把结果逐步显示在下方列表中，屏幕上也会显示进度条。您可以在下方列表中看到每个流星片段持续多长时间、在哪个时间点出现，点击可以预览该片段的画面。</p>
            <p>4. 分析得到的流星结果不一定是准确的，有可能是误报，您可以点击片段右上角的x、或是选中片段后按下del来删除这个片段；您也可以点击星标或是按下f键来为选中的片段打星。</p>
            <p>5. 分析器会按照一定的时间间隔进行工作，这个间隔受到“缓存分片长度”的影响，您会看到一个进度条每隔一定时间在界面上显示，这代表着分析器正在后台工作。</p>
            <p>6. 每一个片段都可以点击片段预览图上方的“快速导出”按钮导出一个视频片段，这个片段将根据您的设置，保存在指定的文件夹中。</p>
            <p>7. 您可以点击“导出所有片段”按钮，您将在弹出的窗口中查看具体的导出选项，也支持将所有片段合并为一个合集视频进行导出。</p>

            <div class="page-header">
              <h1>教程：从延时摄影图片中查找流星</h1>
            </div>
            <p>1. 启动软件，点击“分析图片序列”，并选择一个存放着您的照片序列的文件夹。软件将会查找该文件夹中的图片文件，如果存在RAW图像，将会自动提取RAW中的jpg预览。</p>
            <p>Tips: <b>如何提升提取速度？</b> Meteor Master AI借助了Adobe DNG Converter和内建的jpg提取工具来将RAW处理为可读取的jpg图像。以下两种方式都可以提升提取速度（二选一）：<br />（1）您可以使用我开发的<a href="http://photohelper.cn/RAW2DNG">DNG自动转码工具</a>来优化您的工作流，在您将照片从存储卡导入电脑时，就将这些照片转码为带有完整预览的DNG。<br />（2）您可以提前使用自己喜欢的工具将这些照片处理成jpg（可以预先进行调色以凸显星空和潜在的流星），然后将jpg单独放置到一个文件夹，让Meteor Master AI读取。</p>
            <p>2. 点击“设置蒙版”按钮，并使用鼠标在画面上绘制不需要分析的区域，将其涂抹成红色。可以通过保存和加载蒙版图片文件来复用您的蒙版。您也可以使用自己喜欢的工具进行蒙版的制作，只需要在透明png文件中涂抹红色即可。</p>
            <p>3. 点击确认蒙版（形似对勾的按钮）后，再点击“开始分析”，软件将会持续在后台分析流星，并把结果逐步显示在下方列表中，屏幕上也会显示进度条。您可以在下方列表中看到每个流星片段的照片文件，点击可以预览该片段的画面。</p>
            <p>4. 分析得到的流星结果不一定是准确的，有可能是误报，您可以点击片段右上角的x、或是选中片段后按下del来删除这个片段；您也可以点击星标或是按下f键来为选中的片段打星。</p>
            <p>5. 每一个片段都可以点击片段预览图上方的“打开目录”按钮找到该文件所在的目录。</p>
            <p>6. 您可以点击“导出所有片段”按钮，您将在弹出的窗口中查看具体的导出选项，将带有流星的序列移动或复制到一个特定的目录中。</p>

            
            <div class="page-header">
              <h1>常见问题</h1>
            </div>
            <div class="qa">
              <p class="q">Q：在多台电脑上运需要重新购买软件吗？</p>
              <p class="a">A：如果您想在Mac和Win的电脑上使用本软件，那么需要AppStore和微软商店中分别购买。您购买的Meteor Master AI将会被绑定在您微软或是苹果账号下，您可以在任意多台登录了您微软/苹果账号的电脑上直接运行本软件，无须重复购买。</p>

              <p class="q">Q: 我有多个摄像头，如何同时进行分析？</p>
              <p class="a">A: 您可以在同一台电脑上同时运行多个Meteor Master AI，不限制启动数量。如果摄像头支持推流（如海康威视可以推送rtsp流），那么直接使用“分析直播流”功能即可。如果只支持webcam（如小米监控），您可以将画面输入OBS（OBS也可以多开），然后使用“向Meteor Master AI”推流功能。</p>

              <p class="q">Q: 为什么接入直播没有画面？</p>
              <p class="a">A: 如您是windows电脑，请使用管理员权限启动应用并关闭防火墙；如果您是mac电脑，请为Meteor Master打开摄像头权限。如果您使用的是海康威视的摄像头，请不要使用H265的视频编码。</p>

              <p class="q">Q: 分析直播视频时候CPU占用居高不下怎么办？</p>
              <p class="a">A: 您可以在设置中找到“直播/OBS设置 - 均衡CPU负载”选项，这样可以显著降低cpu占用，非常适合多开的用户。</p>

              <p class="q">Q: 什么样的视频是不被支持的？</p>
              <p class="a">A: 无论是现成的视频还是直播画面，必须是<b>固定的、连续的、干扰较少的、正常速度播放的视频</b>，过于晃动的视频将导致识别内容有大量误报和漏报。如果一个固定机位的视频有移动过相机的位置或是构图，请务必将该视频截成多段固定机位一次完成的视频再进行分析。</p>

              <p class="q">Q: 蒙版应该怎么画？</p>
              <p class="a">A: 打开蒙版面板后，您可以直接按住鼠标在画面中进行绘制（可以使用滚轮缩放画笔大小）。红色区域为<b>不需要分析的区域</b>。通常的，画面中只需要留下天空的区域即可。也就是说，需要将以下内容涂抹成红色：地面、遮挡了天空的树木、山峰、可能会有人来回走动的区域、视频画面上的文字等。蒙版不需要绘制的绝对的精确，可以超出地景范围一部分以获得更好的效果（通常远处地平线会出现非常多的飞机、人造卫星等干扰项）。
                <img class="img-responsive" src="@/assets/img/meteormaster/3.png" alt="" srcset="">
              </p>

              <p class="q">Q：为什么我看不到直播画面？</p>
              <p class="a">A：首先请检查直播信号是否正常，您可以在其他视频软件（如VLC等）中尝试打开这个直播流，看是否正常。您也可以在设置中打开“对直播信号重新编码”选项，强制对视频直播流进行转码。另外，部分用户报告了在mac下无法连接OBS虚拟摄像头，这是OBS的问题，请尝试安装更新的OBS软件或重启你的电脑解决。此外，您还可以通过OBS虚拟摄像头、向Meteor Master AI推流等功能来进行尝试。</p>

              <p class="q">Q：如何使用自动启停功能？</p>
              <p class="a">A：自动启停功能只支持直播/OBS视频。您可以在“开始分析”按钮的旁边看到“定时任务”按钮。设置摄像机的经纬度，并根据其中的选项进行设置。
                <img class="img-responsive" src="@/assets/img/meteormaster/2.jpg" alt="" srcset="">
              
              <p class="q">Q: 为什么我开始分析了好几天后仍然没有得到任何结果？</p>
              <p class="a">A: 众所周知，流星在镜头中划过是偶然事件，我们并无法确保一段时间内100%出现流星并被捕捉到。通常情况下，夜晚即使没有流星，我们也会捕捉到飞机和人造卫星的划过。所以请确保您所在的夜空环境以及您的摄像头确实能够观测到流星，如果您以前使用过其他的流星监测软件（如UFO Capture）且有监测到的记录，如果使用Meteor Master AI完全无法捕捉到任何流星或是人造天体，那么您可能需要检查一下您的拍摄帧率和实际输入Meteor Master AI的视频帧率是否吻合（比如您的摄像头拍摄的是1/3s的快门，也就是3fps，但是在Meteor Master AI的界面上显示您输入的帧率为25fps）。</p>

              <p class="q">Q: 为什么会有大量的误报？</p>
              <p class="a">A: 误报在所难免，Meteor Master AI并无法保证有百分之一百的准确率（即便我们追求的目标是如此），我们做的更多是进行“初选”，给出可能的结果后，由用户最终决定是否为其想要的结果。当出现大量误报后，可以通过以下方式来进行优化：（1）检查您的相机是否有过多的噪点，拍摄环境是否有过多的车流灯光等干扰因素；（2）检查是否正确绘制了蒙版；（3）检查是否启用了过于灵敏的配置文件；（4）您可以打开设置中的“过滤设置”来自动过滤掉过短的结果</p>
              </p>
              <p class="q">Q：如何保存分析的结果？</p>
              <p class="a">A：首先，Meteor Master AI可以非常方便的导出视频片段与流星轨迹图。您可以在设置中开启“自动导出”功能让软件边分析边导出，并可以在分析结束时生成一个合集视频。您也可以在窗口右上角上角找到保存图标，将当前的项目保存为一个meteor文件，以便后续查看。注意，项目文件并不保存视频原文件或是片段，请确保原视频、原直播缓存存放在原地，或是放在meteor文件的旁边。
                <img class="img-responsive" src="@/assets/img/meteormaster/4.jpg" alt="" srcset="">
              </p>
              <p class="q">Q：Meteor Master AI是否支持GPU加速？如何知道我的电脑是否支持GPU加速？</p>
              <p class="a">A：Meteor Master AI引擎支持在windows平台下使用Direct ML加速（请确保您正确安装了DirectX 12）、在Mac平台下支持CoreML加速。Meteor Master AI会自动识别并使用对应的引擎以获得最佳效能。如果您在启动分析后出现了内存报错等现象，可以前往设置中打开对应引擎下的“兼容模式”选项以关闭硬件加速。</p>

              <p class="q">Q：我在安装完成后打开，一直卡死在加载界面上怎么办？(该问题仅针对使用3.x以下的免费版本的用户)</p>
              <p class="a">A：如果您是windows用户，请确保以管理员身份启动软件。软件在第一次启动时会下载并自动安装组件，如果中途关闭，容易导致后续的错误。遇到此种情况请打开【win】C:\Users\用户名\AppData\Roaming\meteormaster、【mac】/Users/用户名/Library/Application Support/meteormaster文件夹，删除engine文件夹并重启软件即可。</p>
  
              <p class="q">Q：为什么在添加dng图片时显示报错？(Please Confirm the DNG file dose have jpg preview on full size.)</p>
              <p class="a">A：您使用的dng图片中并没有全尺寸的预览图。您可以使用本站提供的“DNG转码工具”来自动嵌入全尺寸预览。您也可以使用lr或是acr在导出您的RAW图像时，选择输出为DNG，并选择嵌入JPEG预览-全尺寸。</p>

              <p class="q">Q：RTSP带有用户名密码怎么办？</p>
              <p class="a">A：你可以在直播地址栏中输入：rtsp://Username:Password@192.168.0.1/path/to/adress 来连接画面</p>

              <p class="q">Q：为什么我的电脑无法运行视频识别？直播也不行</p>
              <p class="a">A：请确保您正确安装<a href="https://learn.microsoft.com/en-us/cpp/windows/latest-supported-vc-redist?view=msvc-170">VC运行库</a>以及<a href="https://support.microsoft.com/zh-cn/topic/%E5%A6%82%E4%BD%95%E5%AE%89%E8%A3%85%E6%9C%80%E6%96%B0%E7%89%88%E6%9C%AC%E7%9A%84-directx-d1f5ffa5-dae2-246c-91b1-ee1e973ed8c2">DirectX 12</a> ，重启电脑后尝试运行。</p>
  
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row L3">
      <div class="container">
        <div class="row">
          <div class="page-header">
            <h1>更新日志</h1>
          </div>
          <p>20240906 4.11.0 V8L图片识别模型升级至1.1；图片识别模式中导出增加生成延时摄影视频功能。</p>
          <p>20240830 4.10.0 工具栏新增“筛选类型”按钮（漏斗图标），可以快速在结果栏筛选流星、飞机、红色精灵；移除图片分析设置中的筛选结果功能，此功能已被筛选类型按钮代替；将放大镜功能作为可开关的功能，并在工具栏中新增“放大镜”按钮；已检测出的结果矩形框上添加关闭按钮用于手动清理结果</p>
          <p>20240828 4.9.0 更新图像识别引擎MeteorMasterDetecterAI到2.0，支持AMD、Intel显卡的硬件加速,V5X识别模型升级至V8L；更新MetDetPy到2.1.0，支持GPU硬件加速，修复若干已知问题，降低误报率；直播模式下增加“降低CPU占用”的选项，以降低检测速率换取较低的cpu占用</p>
          <p>20240816 4.8.2 修复mac输入框无法复制粘贴的问题；修复列表筛选后点击删除未正确高亮下一个元素问题；修复检测raw时会重复转码的问题；修复mac上会读取.开头文件导致图片分析卡死的问题；照片导出时可选是否只导出筛选的图片</p>
          <p>20240809 4.8.0 分析引擎升级2.0.2，修复若干已知问题；增加选项，现在可以选择在导出的轨迹图上面添加红框；增加延时摄影视频拆帧功能（在图片分析入口处），可自定义拆帧的方式；修复在首页转码时报错无提示的问题；修复轨迹图导出进度条展示问题</p>
          <p>20240723 4.7.0 可以在导出的视频上添加箭头指示器；在直播模式下可以快速更改url；定时任务面板现在可以记忆使用过的GPS坐标;现在可以选择自动过滤掉时长过短或是长度不足的流星；简化定时器的代码，现在可以跨天执行定时任务，不再限制于00:00~23:59</p>
          <p>20240711 4.6.2 修复n卡导出视频时报错的问题</p>
          <p>20240627 4.6.0 在视频和直播模式中支持导出延时摄影视频；自动化任务支持每天自动导出前一晚的延时摄影视频；导出视频片段时可以在视频上添加流星指示框；支持快速展示隐藏直播地址</p>
          <p>20240412 4.5.0 增加延迟启动选项；增加硬盘剩余容量告警</p>
          <p>20240227 4.4.0 新增一个在识别后自动清理直播缓存的选项，可大幅减小缓存容量；修复在点击批量删除后导致保存项目中数据出错的问题</p>
          <p>20240212 4.3.0 直播缓存可以单独选择文件夹存储；定时任务面板可以精确到分钟调整时间偏移</p>
          <p>20240203 4.2.2 修复一个在win下导致分析卡死的问题</p>
          <p>20240201 4.2.1 修复打开侧边栏时无法正确绘制蒙版的问题；修复侧边栏无流星数量的问题；修复不自动导出轨迹图的问题；修复最新流星轮播图固定显示第—张的问题</p>
          <p>20240131 4.2.0 增加收起底栏的按钮</p>
          <p>20240130 4.1.0 改进副窗口展示内容</p>
          <p>20240126 4.0.0 MetDetPy升级至2.0.1；删除可选是否删除到系统回收站；软件启动时可选轨迹图生成器状态；支持根据日期筛选片段；导出任务的进度展示；定时任务结束后可选自动生成每日视频和合集；导出视频完成后支持快速导航到已导出的片段；直播时，进度条面板显示到下一个切片开始的时间；导出视频支持自定义水印；定时重连直播，防止画面卡死；支持副窗口显示数据和片段；支持推流软件向Meteor Master AI推流，现在可以用OBS多开并连接多个Meteor Master AI了；直播时数据统计粒度的截断时间可选实际时间或是直播时间；视频信息的帧率显示优化；直播分析时，已用时间显示实际操作的时间，不再会因为直播信号中断而重置计时；回收站显示角标数量；工具栏UI精简；导出直播片段时，文件名更改为直播配置的名称；设置中的编码器、编码、导出品质与导出面板中的选项保持同步；修复：当启停计时器其中一项时间选择手动时，计时器停止工作的问题；修复：当信号直播长时间中断后分析不自动重启的问题；修复：Mac下图标进度条闪烁的问题</p>
          <p>20240111 3.6.0 MetyDetPy引擎升级至2.0.0，支持配置文件自定义；MeteorMasterDetectorAI引擎升级至1.1；设置面板、定时任务面板UI更新；对视频直播/OBS增加快速重连按钮；对识别结果增加一键清空按钮、一键跳转到队首、队尾按钮；图片序列识别功能支持查看exif拍摄时间；在未设置坐标的直播/OBS中支持指定坐标并使用自动启停功能；新增直播推流地址</p>
          <p>20231229 3.5.0 全新UI，导出可选200M码率，支持保存超过3000颗流星的单个文件，修复当流星数量特别巨大时的导出崩溃问题，重写数据处理模块，性能提升，降低占用</p>
          <p>20230905 3.4.3 修复个别提示的问题；已上架微软商店</p>
          <p>20230824 3.4.0 绘制蒙版时支持滚轮缩放；增加图片分析结果中只显示流星的选项；增加英文界面</p>
          <p>20230824 3.3.1 新增放大镜功能；支持对已内置全尺寸预览的dng跳过转码；修复部分电脑上CUDA识别错误的问题</p>
          <p>20230818 3.2.1 支持jpeg文件；针对png文件增加有效性检查；修复部分系统上的白屏闪退问题；修复RAW转码出错时反复弹出弹框的问题</p>
          <p>20230815 3.2.0 RAW提取jpg时支持多线程；已提取完成的jpg不会再重复提取；修复直播地址带有问号时无法保存蒙版的问题；修复日志乱码问题</p>
          <p>20230811 3.1.7 修复若干已知问题，针对AppStore版做出相应调整
            <a href="https://pan.baidu.com/s/1B-O8h4DT89y_u1_YKXKGhA&pwd=jz01" target="_blank">旧版本下载</a>
          </p>
          <p>20230729 3.0.3 增加windows下的右键菜单功能，mac平台支持CoreML加速，该版本为原生arm版，放弃对intel版的支持。</p>
          <p>20230709 3.0.2 修复了在识别1000张以上照片的文件夹时不会自动结束的问题</p>
          <p>20230704 3.0.0 添加Meteor Master Detecter AI，支持分析图片中的流星</p>
          <p>20230626 2.5.0 支持H.265编码的视频</p>
          <p>20230411 2.4.2 增加日志log，当识别报错时便于调试</p>
          <p>20230320 2.4.0 增加数据统计图功能</p>
          <p>20230314 2.3.5 增加导出数据excel表</p>
          <p>20230220 2.3.2 支持生成每个片段的流星轨迹图片，修复多个问题</p>
          <p>20230203 2.2.3 导出可选添加编号便于排序；支持多开，不再提示端口占用</p>
          <p>20230202 2.2.0 支持直播中自动启停功能；列表支持按所在时间、按得分、按流星长度、按流星数量、按持续时间排序</p>
          <p>20230201 2.1.0 支持无音频的视频的分析、导出；直播地址支持保存；直播支持保存项目文件；新增“自动删除”功能</p>
          <p>20230131 2.0.9 修复列表导出无响应的问题</p>
          <p>20230130 2.0.8 修复导出超过50条时最后一组的bug，修复部分流星结果丢失的问题</p>
          <p>20230129 2.0.7 修复返回首页的bug；进度条显示前台任务进度，而不是分析器进度</p>
          <p>20230125 2.0.6 增加保存、读取结果功能，导出增加264/265选项，优化批量导出的性能</p>
          <p>20230125 2.0.4 修复导出问题，支持macos</p>
          <p>20230123 2.0.3 修复导出问题，修复列表卡顿问题</p>
          <p>20230113 2.0.0 增加直播分析功能</p>
          <p>20221217 1.3.1 升级查找引擎，增加灵敏度选项，为每颗流星增加置信度得分</p>
          <p>20220411 1.0.0 第一版</p>
        </div>
        <div class="row">
          <div class="page-header">
            <h1>技术支持</h1>
          </div>
          <p>欢迎提供反馈意见或是建议，您可以点击右侧私信我或是通过邮件联系我们：mordom@foxmail.com</p>
          <p>本工具检测器为开源项目，欢迎提交pr：<a href="https://github.com/LilacMeteorObservatory/MetDetPy">github</a></p>
          <p>禁止通过破解、二次打包等方式对软件进行拆包、逆向工程、修改等操作，或在未经作者同意情况下，在非Microsoft Store、AppStore中进行销售，侵权必究</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import '@/assets/swiper.min.css'
export default {
  name: 'RAW2DNG',
  metaInfo: {
    title: 'Meteor Master', // set a title
    meta: [{             // set meta
      name: 'keywords',
      content: 'AI,流星,流星雨,火流星,星座,英仙座,双子座,象限仪座,实时分析,UFO,Capture,解析,找流星,流星监测,CMMO,IMO,ZHR,流量,天文学,天文科普,目视观测,天文现象,观测,星空'
    },
    {
      name: 'description',
      content: '在视频录像、直播画面中快速找出流星。实时监测、自动导出、回看、自动生成合集。'
    }],
    link: []
  },
  components: {
    swiper,
    swiperSlide
  },
  props: {
    
  },
  computed:{
    isWin(){
      return window.navigator.platform == 'Win32'
    },
  },
  data(){
    return {
      publicPath: process.env.BASE_URL,
      
      swiperOpt:{
        loop: true,
        autoplay:{
          delay:5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        speed: 800,
        direction: 'horizontal',
        mousewheel: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          hideOnClick: true, //点击slide时显示/隐藏按钮
        }
      }
    }
  },
  mounted(){
    
  },
  methods:{
    download(link){
      var a = document.createElement('a');
      a.href = link;//下载文件地址
      a.click(); 
    },
  }

}
</script>
<style lang="less" scoped>
	.flex{
		display: flex;
		align-items: center;
		justify-content: flex-start;	
	}
  .container-fluid{
    width: 100%;
    padding: 0;
    margin: 0;
    .bg{
      width: 100%;
      height: 800px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      background: url('../assets/img/meteormaster/coverbg.jpg') no-repeat center;
      background-size: cover;
    }
    .row{
      margin: 0;
      padding: 0;
    }
    .L1{
      color: #fff;
      padding-top: 100px;
      height: 1200px;
      backdrop-filter: blur(10px);
      h1{
        font-size: 64px;
        font-weight: 300;
        margin-top: 70px;
        font-family: "gilroy";
        font-weight: 100;
        text-shadow: 0 0 10px rgba(0,0,0,0.8);
        span{
          font-size: 14px;
          border: 1px solid #fff;
          padding: 0 8px;
          border-radius: 5px;
          line-height: 1;
        }
        @media (max-width: 970px) { 
          margin: 100px 0 50px;
        }
      }
      p{
        font-weight: 300;
        text-shadow: 0 0 10px rgba(0,0,0,0.8);
      }
      .btnbox{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        margin: 60px 0;
        .btn{
          font-size: 16px;
          line-height: 1;
          padding: 8px 20px 8px;
          border: 1px solid #fff;
          color: #fff;
          border-radius: 20px;
          margin: 10px 0;
          display: inline-block;
          &:hover{
            color: #5f9997;
            background-color: #fff;
          }
        }
        a{
          color: #fff;
        }
        &.visible-xs{
          .btn{
            border: none;
            box-shadow: none;
            &:hover{
              background: transparent;
              color: #fff;
            }
          }
        }
        .tip{
          text-align: left;
          width: 175px;
          p{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            i{
              flex-shrink: 0;
              display: inline-block;
              margin-right: 5px;
              --ggs: 0.7;
              color: #67C23A;
            }
          }
        }
      }
      .screenshot{
        width: 100%;
        overflow: hidden;
        text-align: center;
        padding-bottom: 50px;
        border-radius: 10px;
        img{
          width: 100%;
          border-radius: 10px;
          display: inline-block;
          box-shadow: 0 0 30px rgba(0,0,0,0.2);
        }
      }
    }
    .L2{
      .bilibili{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        a{
          line-height: 2;
          img{
            margin-top: 10px;
            width: 800px;
            border-radius: 12px;
            box-shadow: 0 0 10px rgba(0,0,0,0.2);
          }
          &:hover{
            transform: scale(1.02);
          }
        }
      }
      p{
        line-height: 1.75;
        margin-bottom: 1.2em;
      }
      .intro{
        margin-bottom: 15px;
        .flex;
        align-items: flex-start;
        label{
          margin-right: 10px;
          flex-shrink: 0;
          width: 100px;
        }
      }
      h3{
        span{
          display: block;
          font-size: 16px;
          font-weight: normal;
          color: #999;
          margin-top: 5px;
        }
      }
    }
    .qa{
      .q{
        margin-bottom: 0px;
      }
      .a{
        text-indent: -1.3em;
        margin-left: 1.3em;
      }
    }
    @media screen and (max-width:992px){
      .L1{
        height: 800px;
      }
      .L2{
        .bilibili{
          iframe{
            width: 100%;
            height: auto;
          }
        }
        .panel{
          .panel-inbox{
            padding: 10px 20px;
          }
          h4{
            font-size: 28px;
          }
        }
      }
    }
  }
</style>